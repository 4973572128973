<template>
  <VueHtml2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    :filename="contractFileName"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="800px"
    ref="contract"
    @beforeDownload="beforeContractDownload($event)"
  >
    <section slot="pdf-content" class="p-5" style="color: #000;">
      <!-- PAGE #1 -->
      <div class="html2pdf__page-break">
        <h6>Besteller BVBA | Guido Gezellelaan 122 bus 1, 3550 Heusden-Zolder | BE0683.531.086 | tel 011/36.19.86</h6>
        <div class="mt-3 mb-3 pb-3 d-flex flex-row text-center border-bottom">
          <img src="/img/menute.be/logo-icon.png" width="120" />
          <h2 class="ml-4 flex-fill align-self-center">{{ appTitle }}<br />({{ $t('contract.registration_certificate') }})</h2>
        </div>

        <table class="table table-borderless table-contract">
          <tr>
            <td class="col-4">{{ $t('contract.restaurant_name') }}</td>
            <td class="col-8">: {{ form.restaurant_name }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.restaurant_address') }}</td>
            <td class="col-8">: {{ form.restaurant_address }} {{ form.restaurant_postcode }} {{ form.restaurant_city }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.restaurant_tel') }}</td>
            <td class="col-8">: {{ form.restaurant_tel }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.restaurant_gsm') }}</td>
            <td class="col-8">: {{ form.restaurant_gsm }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.firma_naam') }}</td>
            <td class="col-8">: {{ form.firma_naam }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.firma_mz_adres') }}</td>
            <td class="col-8">: {{ form.firma_mz_adres }} {{ form.firma_mz_postcode }} {{ form.firma_mz_city }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.website') }}</td>
            <td class="col-8">: {{ form.website }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.firma_type') }}</td>
            <td class="col-8">: {{ form.firma_type }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.btw_nummer') }}</td>
            <td class="col-8">: {{ form.btw_nummer }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.eigenaar1_naam') }}</td>
            <td class="col-8">: {{ form.eigenaar1_naam }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.eigenaar1_gsm') }}</td>
            <td class="col-8">: {{ form.eigenaar1_gsm }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.eigenaar1_email') }}</td>
            <td class="col-8">: {{ form.eigenaar1_email }}</td>
          </tr>

          <tr>
            <td class="col-4">{{ $t('contract.eigenaar2_naam') }}</td>
            <td class="col-8">: {{ form.eigenaar2_naam }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.eigenaar2_gsm') }}</td>
            <td class="col-8">: {{ form.eigenaar2_gsm }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.eigenaar2_email') }}</td>
            <td class="col-8">: {{ form.eigenaar2_email }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.bank_rekeningnummer') }}</td>
            <td class="col-8">: {{ form.bank_rekeningnummer }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.bank_rekeninghouder') }}</td>
            <td class="col-8">: {{ form.bank_rekeninghouder }}</td>
          </tr>
          <tr>
            <td class="col-4">{{ $t('contract.bank_naam') }}</td>
            <td class="col-8">: {{ form.bank_naam }}</td>
          </tr>
        </table>

        <p class="mb-3" v-html="$t('contract.description1', {appTitle: this.appTitle, commission: form.type_commission == 'A' ? '7' : form.type_commission == 'B' ? '8' : form.type_commission == 'C' ? '9' : form.type_commission == 'D' ? '10' : form.type_commission == 'E' ? '11' : form.type_commission == 'F' ? '12' : form.type_commission == 'G' ? '13' : '14' })">
        </p>
        <p>{{ $t('contract.description2') }}</p>
        <p>{{ $t('contract.description3') }}</p>

        <table class="table table-borderless table-sign">
          <tr class="first-row">
            <td class="col-2">{{ $t('contract.place') }}</td>
            <td class="col-4 fill">: {{ formContract.place }}</td>
            <td class="col-2">{{ $t('contract.name') }}</td>
            <td class="col-4 fill">: {{ formContract.name }}</td>
          </tr>
          <tr>
            <td class="col-2">{{ $t('contract.signature') }}</td>
            <td class="col-4"><img width="90%" :src="sign" /></td>
            <td class="col-2">{{ $t('contract.date') }}</td>
            <td class="col-4 fill">: {{ new Date().toJSON().slice(0,10).replace(/-/g,'/') }}</td>
          </tr>
        </table>
        <!-- <span class="text-muted float-right" style="margin-top: 0rem">Page 1 of 2</span> -->
      </div>

      <!-- PAGE #2 -->
      <div>
        <h6>Besteller BVBA | Guido Gezellelaan 122 bus 1, 3550 Heusden-Zolder | BE0683.531.086 | tel 011/36.19.86</h6>
        <div class="mt-3 mb-3 pb-3 d-flex flex-row text-center border-bottom">
          <img src="/img/menute.be/logo-icon.png" width="120" />
          <h2 class="ml-4 flex-fill align-self-center">{{ appTitle }} <br />{{ $t('contract.technical_information') }}</h2>
        </div>

        <h5 class="mb-2">{{ $t('contract.delivery_zones') }}:</h5>
        <table class="table table-sm table-zip-codes">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ $t('contract.region') }}</th>
              <th scope="col">{{ $t('contract.min_amount') }}</th>
              <th scope="col">{{ $t('contract.delivery_charge') }}</th>
              <th scope="col">{{ $t('contract.free_from') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(zip_code, index) in form.zip_codes" :key="zip_code.id">
              <td class="col-1">{{ index + 1 }}</td>
              <td class="col-5">{{ zip_code.zcZip }} {{ zip_code.zcCity }}</td>
              <td class="col-2">{{ zip_code.pivot.minimum_order | toCurrency }}</td>
              <td class="col-2">{{ zip_code.pivot.delivery_charge | toCurrency }}</td>
              <td class="col-2">{{ zip_code.pivot.free_above | toCurrency }}</td>
            </tr>
          </tbody>
        </table>

        <h5 class="mt-4 mb-2">{{ $t('contract.opening_closing_hours') }}:</h5>
        <table class="table table-sm table-opening-hours">
          <tbody>
            <tr>
              <!-- Pazartesi -->
              <td class="col-1"><strong>{{ $t('contract.monday') }}</strong></td>
              <td class="col-5">: {{ form.open_monday }}</td>

              <!-- Cuma -->
              <td class="col-1"><strong>{{ $t('contract.friday') }}</strong></td>
              <td class="col-5">: {{ form.open_friday }}</td>
            </tr>
            <tr>
              <!-- Salı -->
              <td class="col-1"><strong>{{ $t('contract.tuesday') }}</strong></td>
              <td class="col-5">: {{ form.open_tuesday }}</td>

              <!-- Cumartesi -->
              <td class="col-1"><strong>{{ $t('contract.saturday') }}</strong></td>
              <td class="col-5">: {{ form.open_saturday }}</td>
            </tr>
            <tr>
              <!-- Çarşamba -->
              <td class="col-1"><strong>{{ $t('contract.wednesday') }}</strong></td>
              <td class="col-5">: {{ form.open_wednesday }}</td>

              <!-- Pazar -->
              <td class="col-1"><strong>{{ $t('contract.sunday') }}</strong></td>
              <td class="col-5">: {{ form.open_sunday }}</td>
            </tr>
            <tr>
              <!-- Perşembe -->
              <td class="col-1"><strong>{{ $t('contract.thursday') }}</strong></td>
              <td class="col-5" colspan="3">: {{ form.open_thursday }}</td>
            </tr>
          </tbody>
        </table>

        <h5 class="mt-4 mb-2">{{ $t('contract.delivery_hours') }}:</h5>
        <table class="table table-sm table-delivery-hours">
          <tbody>
            <tr>
              <!-- Pazartesi -->
              <td class="col-1"><strong>{{ $t('contract.monday') }}</strong></td>
              <td class="col-5">: {{ form.deliver_monday }}</td>

              <!-- Cuma -->
              <td class="col-1"><strong>{{ $t('contract.friday') }}</strong></td>
              <td class="col-5">: {{ form.deliver_friday }}</td>
            </tr>
            <tr>
              <!-- Salı -->
              <td class="col-1"><strong>{{ $t('contract.tuesday') }}</strong></td>
              <td class="col-5">: {{ form.deliver_tuesday }}</td>

              <!-- Cumartesi -->
              <td class="col-1"><strong>{{ $t('contract.saturday') }}</strong></td>
              <td class="col-5">: {{ form.deliver_saturday }}</td>
            </tr>
            <tr>
              <!-- Çarşamba -->
              <td class="col-1"><strong>{{ $t('contract.wednesday') }}</strong></td>
              <td class="col-5">: {{ form.deliver_wednesday }}</td>

              <!-- Pazar -->
              <td class="col-1"><strong>{{ $t('contract.sunday') }}</strong></td>
              <td class="col-5">: {{ form.deliver_sunday }}</td>
            </tr>
            <tr>
              <!-- Perşembe -->
              <td class="col-1"><strong>{{ $t('contract.thursday') }}</strong></td>
              <td class="col-5" colspan="3">: {{ form.deliver_thursday }}</td>
            </tr>
          </tbody>
        </table>

        <h5 class="mt-4 mb-2">{{ $t('contract.restaurant_types') }}:</h5>
        <ul class="nav restaurant-types">
          <li v-for="(restaurant_type) in form.restaurant_types" :key="restaurant_type.id">
            {{ restaurant_type.type }}
          </li>
        </ul>

        <h5 class="mt-4 mb-2">{{ $t('contract.payment_method') }}:</h5>
        <h6 class="mt-4 mb-2">{{ $t('contract.connection_method') }}:
          {{
            {
              box: "MagicBox",
              soft: "Etenonline Software",
              mail: "E-Mail",
            }[form.connectiewijze]
          }}
          <template v-if="form.connectiewijze == 'box'">
            (
              {{
                {
                  rent: "Huur",
                  buy: "Aankoop",
                }[form.boxpaymenttype]
              }}
              <template v-if="form.boxpaymenttype == 'rent'">
                , {{ form.boxrentstartdate }}
              </template>
            )
          </template>
        </h6>

        <h5 class="mt-4 mb-2">{{ $t('contract.comments') }}:</h5>

        <!-- <span class="text-muted float-right" style="margin-top: 5rem">Page 2 of 2</span> -->
      </div>
    </section>
  </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'  // https://www.npmjs.com/package/vue-html2pdf

export default {
  name: "RestaurantContract",
  components: {
    VueHtml2pdf
  },
  props: {
    form: { default: null, type: Object },
    formContract: { default: null, type: Object },
    sign: { default: null, type: String },
    saveFile: { default: true, type: Boolean },
  },

  computed: {
    appTitle() { return process.env.VUE_APP_APP_TITLE },
    slugable() { return this.$globalFunc.slugify(`${this.form.restaurant_postcode}-${this.form.restaurant_name}-${this.form.restaurant_city}`) },
    contractFileName() { return `contract_${this.slugable}` },
  },

  async mounted() {
  },

  methods: {
    async generatePdf() {
      await this.$refs.contract.generatePdf();
    },

    async beforeContractDownload({ html2pdf, options, pdfContent }) {
      // This will be triggered before the PDF generation and download.
      const pdf = await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        pdf.text(
          `Page ${i} / ${totalPages}`,
          pdf.internal.pageSize.getWidth() * 0.88,
          pdf.internal.pageSize.getHeight() - 0.3
        );
      }

      if (this.saveFile) {
        pdf.save(this.contractFileName);
      }

      // Generate the PDF document as a blob and then upload it
      this.uploadContract(pdf.output("blob", options));
    },

    async _beforeContractDownload({ html2pdf, options, pdfContent }) {
      // This will be triggered before the PDF generation and download.
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }

          // Generate the PDF document as blob  then upload it
          this.uploadContract(pdf.output("blob", options));
        })
        .save();
    },

    async uploadContract(pdfContent) {
      const extension = '.pdf';

      const formData = new FormData();
      formData.append("file", pdfContent, (this.contractFileName + extension));
      formData.append('content_type', 'contract');
      formData.append('slugable', this.slugable);
      formData.append('extension', extension);

      const { data } = await this.$axios.post(this.$backend.RESTAURANT.UPLOAD_FILE, formData);
      const fileName = data.data.file;
      // this.form.upload_contract = fileName
      this.$emit("generated", fileName);
    },
  },
};
</script>

<style scoped>
.table-contract,
.table-sign,
.table-zip-codes,
.table-opening-hours,
.table-delivery-hours {
  color: #000;
}
.table-contract td {
  padding: 0.1rem 0.75rem;
}
.table-sign tr.first-row > td {
  padding-top: 2em;
  padding-bottom: 1em;
}
.table-sign .fill {
  font-weight: bold;
}

.restaurant-types li:not(:last-child):after {
  padding-right: 0.3rem;
  content: ",";
}
</style>
